<template>
  <div id="app-base-subwindow-ipc">
    <div id="video_box">
      <video ref="vplayer_start" id="vplayer_start"></video>
      <video ref="vplayer" id="vplayer"></video>
    </div>
    <!-- <audio ref="ado" :src="audioUrl"></audio> -->
  </div>
</template>
<script>
import { ipcApiRoute, specialIpcRoute, ipcRenderer } from "@/api/main";
import { get_lead } from "@/api/openlive.js";
import { shuffle } from "@/utils/tools";
export default {
  data() {
    return {
      startPlay: true,
      vplayer_start: null,
      vplayer: null,
      list: [],
      vList: [],
      curr: 0,
      live_id: "",
      lead_times: "",
      active: "vplayer_start",
      localPath: "",
      audioUrl: "",
      liveInfo: {},
      livelist: [],
      audiolist: [],
      music: null,
      token: null,
      israndom: false,
    };
  },
  mounted() {
    this.init();
    this.initAudio();
  },
  methods: {
    init() {
      console.log("token:", localStorage.getItem("token"));
      this.$ipc.removeAllListeners(specialIpcRoute.window1ToWindow2);
      this.$ipc.on(specialIpcRoute.window1ToWindow2, (event, arg) => {
        console.log("arg:", arg);
        if (arg.type == "insertVideo") {
          this.vList.splice(this.curr, 0, {
            id: arg.id,
            url: `${this.localPath}\\${arg.id}hc.mp4`,
            goods_id: "",
          });
          console.log(this.curr, this.vList);
          this.curr--;
          if (this.active == "vplayer") {
            this.setSrc(this.vplayer_start);
          } else {
            this.setSrc(this.vplayer);
          }
        } else if (arg.type == "playAudio") {
          this.audiolist.push(arg.audioUrl);
          if (this.audiolist.length == 1) {
            this.playAud();
          }
        } else {
          this.localPath = arg.path;
          this.israndom = arg.option.israndom;
          this.live_id = arg.option.live_id;
          this.lead_times = arg.option.lead_times;
          this.token = arg.token;
          console.log(this.token, 1111111111);
          let arr = arg.livelist;
          if (arr.length == 1) {
            arr.push(arr[0]);
          }
          arr.map((i) => {
            this.list.push({
              id: i.id,
              url: `${this.localPath}\\${i.id}hc.mp4`,
              goods_id: i.goods_id,
            });
          });
          console.log(this.list);
          if (this.israndom) {
            this.list = shuffle(this.list);
          }
          this.getLead();
          this.videoRule();
        }
      });
    },
    initAudio() {
      this.music = new Audio();
      this.music.volume = 1;
      this.music.addEventListener("ended", (e) => {
        console.log("end");
        this.audiolist.shift();
        if (this.audiolist.length > 0) {
          this.playAud();
        } else {
          this.vplayer_start.volume = 1;
          this.vplayer.volume = 1;
        }
      });
      this.music.addEventListener("error", (e) => {
        console.log("end");
        this.audiolist.shift();
        if (this.audiolist.length > 0) {
          this.playAud();
        } else {
          this.vplayer_start.volume = 1;
          this.vplayer.volume = 1;
        }
      });
    },

    playAud() {
      if (this.audiolist.length > 0) {
        this.music.src = this.audiolist[0];
        this.music.play();
        this.vplayer_start.volume = 0;
        this.vplayer.volume = 0;
        console.log(this.$refs.vplayer);
      }
    },
    getLead() {
      setInterval(() => {
        get_lead({
          live_id: this.live_id,
          token: this.token,
        }).then((res) => {
          if (res.code == "200") {
            console.log(res.data);
            if (res.data.url) {
              this.audiolist.push(res.data.url);
              if (this.audiolist.length == 1) {
                this.playAud();
              }
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      }, this.lead_times * 1000);
    },

    videoRule() {
      this.vplayer_start = this.$refs.vplayer_start;
      this.vplayer = this.$refs.vplayer;
      this.vList.push(...this.list);

      console.log("v1", this.vplayer_start);
      console.log("v2", this.vplayer);
      this.vplayer_start.src = this.vList[0].url;

      this.vplayer_start.style.visibility = "visible";
      this.vplayer_start.play();
      //第一个播放器开始播放的监听
      this.vplayer_start.addEventListener("play", (e) => {
        this.setSrc(this.vplayer);
        this.active = "vplayer_start";
      });
      // 第一个播放器播放结束
      this.vplayer_start.addEventListener("ended", (e) => {
        e.target.pause();
        this.play(this.vplayer);
        this.vplayer_start.style.visibility = "hidden";
        this.vplayer.style.visibility = "visible";
      });
      //第二个播放器开始播放的监听
      this.vplayer.addEventListener("play", (e) => {
        this.setSrc(this.vplayer_start);
        this.active = "vplayer";
      });
      //第二个播放器播放完成
      this.vplayer.addEventListener("ended", (e) => {
        e.target.pause();
        this.play(this.vplayer_start);
        this.vplayer.style.visibility = "hidden";
        this.vplayer_start.style.visibility = "visible";
      });

      this.vplayer.addEventListener("canplay", (e) => {
        if (this.startPlay) {
          e.target.pause();
          this.vplayer_start.style.visibility = "visible";
          this.vplayer_start.play();
          this.startPlay = false;
        }
      });
    },
    setSrc(video) {
      this.curr++;
      if (this.curr >= this.vList.length) {
        this.curr = 0; //重新循环播放
        this.vList = [];
        let arr = this.israndom ? shuffle(this.list) : this.list;
        this.vList.push(...arr);
        console.log("重置", this.vList);
      }
      this.sendTosubWindow(this.vList[this.curr]);
      video.src = this.vList[this.curr].url;
    },
    play(video) {
      video.play();
    },

    sendTosubWindow(info) {
      // 获取主窗口id
      this.$ipc.invoke(ipcApiRoute.getWCid, "main").then((id) => {
        this.mainWCid = id;
        this.$ipc.sendTo(this.mainWCid, specialIpcRoute.window2ToWindow1, { msg: "[LiveMsg]:video change", info });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.vc-toggle .vc-panel {
  bottom: 0;
  min-height: 65% !important;
}

#video_box {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

video {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
}

#app-base-subwindow-ipc {
  padding: 0px 10px;
  text-align: left;
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;

  .one-block-1 {
    font-size: 16px;
    padding-top: 10px;
  }

  .one-block-2 {
    padding-top: 10px;
  }
}
</style>
